<template>
  <section>
    <SpinnerComponent v-if="verificacionPIP.isLoading" spinning text="Cargando información..." />
    <SpinnerComponent v-else-if="verificacionParticipacionComision.isLoading" spinning text="Cargando información..." />
    <template v-else>
      <v-tabs v-model="idTab" background-color="bgMinsal" show-arrows>
        <v-tab
          v-for="item in tabs"
          :key="item.id"
          class="secondary--text"
          style="text-transform: none !important"
          v-if="!item.hide"
        >
          <v-icon>{{ item.icon }}</v-icon>
          <span class="pl-1">{{ item.tab }}</span>
        </v-tab>
      </v-tabs>
  
      <EmptyComponent 
        :empty="tabs.length === 0 && !verificacionPIP.isLoading && !verificacionParticipacionComision.isLoading"
        title="El proceso se encuentra en recurso de revisión"
        text=""
        icon="mdi-file-alert"
      >
        <v-tabs-items v-model="idTab" class="px-1 pb-4">
          <v-tab-item v-for="item in tabs" :key="item.id" v-if="!item.hide">
            <component
              :is="item?.content"
              :proceso="procesoData"
              class="mt-8"
              v-bind="{ 
                ...item.propiedad_solo_lectura && ({ soloLectura: item.propiedad_solo_lectura }),
                ...item.propiedad_get_proceso && ({ getProceso }),
              }"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </EmptyComponent>
    </template>
  </section>
</template>

<script>
import { mapState } from "vuex";
// import ProveedorEtapaActualComponent from "./components/ProveedorEtapaActualComponent.vue";
import UcpEtapaActualComponent from "./components/UcpEtapaActualComponent.vue";
import TacopEtapaActualComponent from "./components/TacopEtapaActualComponent.vue";
import EtapasProcesoImpugnacionesComponent from "./components/EtapasProcesoImpugnacionesComponent.vue";
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import { SpinnerComponent } from '@/components/utils';
import PIPEditable from './components/PIPEditable.vue';
import { mapActions } from 'vuex';
import EtapaActualComponent from "../components/EtapaActualComponent.vue";
import { Listener, Observable } from "@/utils/observable";
import InfoGeneralComponent from "../components/InfoGeneralComponent.vue";
import ContainerAdminComponent from "../components/ContainerAdminComponent.vue";
import DocumentsProccessComponent from "../components/DocumentsProccessComponent.vue";
import ParticipantesComponent from "../components/ParticipantesComponent.vue";
import PIPComponent from "../components/PIPComponent.vue";
import { isNil } from "lodash";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";
import MainAuditoriaComponent from "../AuditoriaProcesos/MainAuditoriaComponent.vue";

  export default {
    name: "ProcesoImpugnacionesComponent",
    components: { SpinnerComponent, EmptyComponent },
    data: () => ({
      idTab: 0,
      verificacionPIP: createLoadable(null),
      unsubscribe: null,
      // verificacionParticipacionComision: createLoadable(null),
    }),
    props: {
      getProceso: { type: Function },
    },
    computed: {
      ...mapState("procesoCompraDoc", ["procesoData", "participando", "siguientesEtapasImpugnacion", "verificacionParticipacionComision"]),
      tieneParticipacionEnComision() {
        return this.verificacionParticipacionComision.data?.participa_en_comision;
      },
      cargandoSiguientesEtapas() {
        return this.siguientesEtapasImpugnacion.isLoading;
      },
      ocultarAdjudicacion() {
        const siguienteEtapa = this.siguientesEtapasImpugnacion.data[0];

        if (!siguienteEtapa) return false;

        return [26].includes(siguienteEtapa.id_tipo_etapa);
      },      
      contenido() {
        if (this.haveRole('ROLE_PROVEEDOR')) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: this.verificacionPIP.data === 'general' ? EtapaActualComponent : UcpEtapaActualComponent,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: EtapasProcesoImpugnacionesComponent,
              hide: this.ocultarAdjudicacion || this.cargandoSiguientesEtapas || this.verificacionPIP.data === 'general',
            },
          ];
        }

        if (this.tieneParticipacionEnComision) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: this.verificacionPIP.data === 'general' ? EtapaActualComponent : UcpEtapaActualComponent,
              propiedad_get_proceso: true,
            },
            {
              id: 2,
              tab: "Etapas de recurso de revisión",
              icon: "mdi-format-list-bulleted",
              content: this.verificacionPIP.data === 'general' ? PIPEditable : EtapasProcesoImpugnacionesComponent,
            },
            {
              id: 3,
              tab: "General",
              icon: "mdi-file-cog",
              content: InfoGeneralComponent,
            },
            {
              id: 4,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: PIPComponent,
            },
            {
              id: 5,
              tab: "Documentos de solicitud de oferta",
              icon: "mdi-feature-search-outline",
              content: ContainerAdminComponent,
              hide: ![3, 11].includes(this.procesoData.id_seguimiento_proceso),
              propiedad_solo_lectura: true,
            },
            {
              id: 6,
              tab: "Documentos del proceso",
              icon: "mdi-file-document-multiple",
              content: DocumentsProccessComponent,
              hide: [27, 25, 18].includes(this.procesoData.id_forma_contratacion),
              propiedad_solo_lectura: true,
            },
            {
              id: 7,
              tab: "Participantes",
              icon: "mdi-account-group",
              content: ParticipantesComponent,
              hide: [27, 25, 18].includes(this.procesoData.id_forma_contratacion),
              propiedad_solo_lectura: true,
            },
          ];

        }

        if (this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION'])) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: this.verificacionPIP.data === 'general' ? EtapaActualComponent : UcpEtapaActualComponent,
              propiedad_get_proceso: true,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: this.verificacionPIP.data === 'general' ? PIPEditable : EtapasProcesoImpugnacionesComponent,
            },
          ];
        }

        if (this.haveRole('ROLE_TACOP')) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: this.verificacionPIP.data === 'general' ? EtapaActualComponent : TacopEtapaActualComponent,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: EtapasProcesoImpugnacionesComponent,
              hide: this.ocultarAdjudicacion || this.cargandoSiguientesEtapas || this.verificacionPIP.data === 'general',
            },
          ];
        }

        if (this.haveRoles(['ROLE_VISUALIZAR_DOCUMENTOS_DURANTE_RECURSO'])) {
          return [
            {
              id: 6,
              tab: "Documentos del proceso",
              icon: "mdi-file-document-multiple",
              content: DocumentsProccessComponent,
              hide: [27, 25, 18].includes(this.procesoData.id_forma_contratacion),
              propiedad_solo_lectura: true,
            },
          ];
        }

        return [];
      },
      tabs() {
        const tabsBase = this.contenido;

        if (this.haveRoles(["ROLE_SUPER_ADMIN", "ROLE_AUDITORIA_PROCESO"])) {
          tabsBase.push({
            id: 100,
            tab: "Auditoría del proceso",
            icon: "mdi-package-variant",
            content: MainAuditoriaComponent,
          });
        }

        return tabsBase;
      },
    },
    methods: {
      ...mapActions("procesoCompraDoc", ["cargarSiguientesEtapasImpugnacion", "verificarParticipacionComisionAltoNivel"]),
      isNil,
      async verificarPipActivo() {
        toggleLoadable(this.verificacionPIP);
        const { data } = await this.services.PacProcesos.verificarPIPActualRecursoRevision(this.$route.params.idProceso);
        setLoadableResponse(this.verificacionPIP, data);
      },
    },
    created() {
      this.verificarPipActivo();
      this.cargarSiguientesEtapasImpugnacion(this.$route.params.idProceso);
      this.verificarParticipacionComisionAltoNivel(this.$route.params.idProceso);

      const listener = new Listener('ProcesoImpugnacionesComponent').setHandler(() => this.verificarPipActivo());
      this.unsubscribe = Observable.getInstance().subscribe('revalidar-pip-activo', listener);
    },
    beforeDestroy() {
      this.unsubscribe?.();
    },
  };
</script>
