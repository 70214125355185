var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[(
      _vm.moment().isAfter(_vm.item.fecha_hora_final) &&
      _vm.moment().isAfter(_vm.item.fecha_hora_inicio) &&
      _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']) &&
      _vm.hayGanador
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.item.acciones_pendientes[0]?.adjudicado === false)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.adjudicar(_vm.item.id)}}},[_c('v-icon',[_vm._v("mdi-info")]),_c('span',{staticClass:"mx-2"},[_vm._v("Generar adjudicación")])],1):(
          _vm.item.acciones_pendientes[0]?.adjudicado === true &&
          _vm.item.acciones_pendientes[0].notificado === false
        )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.enviarCorreos(_vm.item.id)}}},[_c('v-icon',[_vm._v("mdi-info")]),_c('span',{staticClass:"mx-2"},[_vm._v("Enviar notificaciones de correo")])],1):_c('v-alert',{staticClass:"success"},[_vm._v(" Ya se ha realizado la adjudicación y notificación por correo con respecto a esta subasta ")])],1),(_vm.haveRoles(['ROLE_USER_ADMIN', 'ROLE_AUDITORIA_SUBASTA_EXCEL']))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"color":"white !important"},attrs:{"color":"warning"},on:{"click":function($event){return _vm.setModalAuditoria(true)}}},[_vm._v("Generar Auditoria")])],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('detalleSubasta',{attrs:{"item":_vm.item}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('subastaGanador',{attrs:{"id":_vm.item.id,"finalizada":_vm.moment().isAfter(_vm.item.fecha_hora_final)},on:{"detalleGanador":_vm.cambiarSiHayGanador}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('detalleLote',{attrs:{"id_lote":_vm.item.id_lote}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('historialPujasComponent',{attrs:{"id":_vm.item.id,"porcentual":[
            'resta_porcentaje_publicidad',
            'resta_porcentaje',
            'suma_porcentaje',
          ].includes(_vm.item?.tipo)}})],1)],1),_c('v-dialog',{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.modalAuditoria),callback:function ($$v) {_vm.modalAuditoria=$$v},expression:"modalAuditoria"}},[_c('v-card',[_c('v-btn',{staticClass:"close-button",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setModalAuditoria(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',{staticStyle:{"justify-content":"center"}},[_vm._v("Generar Auditoria")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" ¿Deseas programar una fecha para la generación del reporte o generarlo de inmediato? ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.generarAhora}},[_vm._v("Generar Ahora")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.setModalProgramar(true)}}},[_vm._v("Programar")])],1)],1)],1),_c('ModalProgramarAuditoria')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }