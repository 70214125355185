<template>
    <v-dialog v-model="modalProgramar" max-width="400" persistent>
        <v-card>
            <v-btn icon small class="close-button" @click="cerrarModal">
                <v-icon>mdi-close</v-icon>
            </v-btn>
    
            <v-card-title class="headline text-center">Programar Auditoría</v-card-title>
    
            <v-card-text class="text-center">
            <v-date-picker
                :value="fechaSeleccionada"
                @input="setFecha"
                @change="onDateChange"
                :min="minFecha"
                color="primary"
                class="mb-4"
            />
    
            <v-time-picker
                v-if="!isTimePickerDisabled"
                :value="horaSeleccionada"
                @input="setHora"
                :min="horaMinima"
                format="24hr"
                color="primary"
                class="mb-4"
            />
    
            <p>
                <strong>Fecha y hora seleccionada: </strong>
                {{ fechaHoraFormateada }}
            </p>
            </v-card-text>
    
            <v-card-actions class="justify-center">
            <v-btn color="primary" @click="confirmar">Confirmar</v-btn>
            <v-btn color="secondary" @click="cerrarModal">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapState("auditoriaSubasta", [
            "modalProgramar",
            "fechaSeleccionada",
            "horaSeleccionada",
            "minFecha",
            "horaMinima",
            "isTimePickerDisabled",
        ]),
        ...mapGetters("auditoriaSubasta", ["fechaHoraFormateada"]),
    },
    methods: {
        ...mapMutations("auditoriaSubasta", [
        "setFecha",
        "setHora",
        "setModalAuditoria",
        "setModalProgramar",
        ]),
        ...mapActions("auditoriaSubasta", ["onDateChange", "confirmarProgramacion"]),

        cerrarModal() {
            this.setModalProgramar(false);
            this.$emit("cerrar"); // Emite evento para informar al padre
        },

        confirmar(){
            this.confirmarProgramacion();
            this.setModalProgramar(false);
            this.setModalAuditoria(false);
        }
    },
};
</script>

<style scoped>
    .close-button {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
    }
</style>