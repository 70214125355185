<template>
  <Fragment>
    <v-row
      v-if="
        moment().isAfter(item.fecha_hora_final) &&
        moment().isAfter(item.fecha_hora_inicio) &&
        haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']) &&
        hayGanador
      "
    >
      <v-col cols="12" md="12">
        <v-btn
          color="primary"
          @click="adjudicar(item.id)"
          v-if="item.acciones_pendientes[0]?.adjudicado === false"
        >
          <v-icon>mdi-info</v-icon>
          <span class="mx-2">Generar adjudicación</span>
        </v-btn>
        <v-btn
          color="primary"
          @click="enviarCorreos(item.id)"
          v-else-if="
            item.acciones_pendientes[0]?.adjudicado === true &&
            item.acciones_pendientes[0].notificado === false
          "
        >
          <v-icon>mdi-info</v-icon>
          <span class="mx-2">Enviar notificaciones de correo</span>
        </v-btn>
        <v-alert class="success" v-else>
          Ya se ha realizado la adjudicación y notificación por correo con
          respecto a esta subasta
        </v-alert>
      </v-col>
      <v-col cols="auto" v-if="haveRoles(['ROLE_USER_ADMIN', 'ROLE_AUDITORIA_SUBASTA_EXCEL'])">
        <v-btn color="warning" style="color: white !important;"  @click="setModalAuditoria(true)">Generar Auditoria</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="6">
            <detalleSubasta :item="item" />
          </v-col>
          <v-col cols="12" md="6">
            <subastaGanador
              :id="item.id"
              :finalizada="moment().isAfter(item.fecha_hora_final)"
              @detalleGanador="cambiarSiHayGanador"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <detalleLote :id_lote="item.id_lote" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <historialPujasComponent
          :id="item.id"
          :porcentual="
            [
              'resta_porcentaje_publicidad',
              'resta_porcentaje',
              'suma_porcentaje',
            ].includes(item?.tipo)
          "
        />
      </v-col>
    </v-row>
    <!-- Modal para generar Auditoria -->
    <v-dialog v-model="modalAuditoria" max-width="400" persistent>
      <v-card>
        <v-btn icon small class="close-button" @click="setModalAuditoria(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title style="justify-content: center;">Generar Auditoria</v-card-title>
        <v-card-text class="text-center">
          ¿Deseas programar una fecha para la generación del reporte o generarlo de inmediato?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="generarAhora">Generar Ahora</v-btn>
          <v-btn color="secondary" @click="setModalProgramar(true)">Programar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para programar la Auditoria -->
    <ModalProgramarAuditoria/>
    <!-- <v-dialog v-model="modalProgramar" max-width="400" persistent>
      <v-card>
        <v-btn icon small class="close-button" @click=cerrarModal>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title class="headline text-center">Programar Auditoria</v-card-title>
        
        <v-card-text class="text-center">
          <v-date-picker v-model="fechaSeleccionada" @change="onDateChange()" :min="minFecha" color="primary" class="mb-4"></v-date-picker>
          <v-time-picker v-if="!isTimePickerDisabled" v-model="horaSeleccionada" :min="horaMinima" format="24hr" color="primary" class="mb-4"></v-time-picker>
          <p>
            <strong>Fecha y hora seleccionada: </strong>
            {{ fechaHoraFormateada }}
          </p>
        </v-card-text>
        
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="confirmarProgramacion">Confirmar</v-btn>
          <v-btn color="secondary" @click="cerrarModal">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </Fragment>
</template>
<script>
import historialPujasComponent from "./HistorialPujasComponent.vue";
import detalleLote from "./DetalleLote.vue";
import subastaGanador from "./SubastaGanador.vue";
import detalleSubasta from "./DetalleSubasta.vue";
import detallesGanadores from "./DetallesGanadores.vue";
import { Fragment } from "vue-fragment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalProgramarAuditoria from "./ModalProgramarAuditoria.vue";

export default {
  name: "AgregarAccionistaV2Component",
  components: {
    Fragment,
    historialPujasComponent,
    detalleLote,
    subastaGanador,
    detalleSubasta,
    detallesGanadores,
    ModalProgramarAuditoria,
  },
  data: () => ({
    hayGanador: false,
    // modalAuditoria: false,
    // modalProgramar: false, 
    // fechaSeleccionada: null, 
    // minFecha: new Date().toLocaleDateString('en-CA'),
    // horaSeleccionada: null,
    // horaMinima: null,
    // isTimePickerDisabled: true,
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
    id_proceso: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState("auditoriaSubasta", [
      // "idSubasta", 
      "modalAuditoria", 
      // "mensaje",
      // "fechaSeleccionada",
      // "horaSeleccionada",
      // "isTimePickerDisabled",
      // "horaMinima",
    ]),
    ...mapGetters("auditoriaSubasta", ["fechaHoraFormateada"]),

    // fechaHoraFormateada() {
    //   if (this.fechaSeleccionada && this.horaSeleccionada) {
    //     return `${this.fechaSeleccionada} ${this.horaSeleccionada}`;
    //   }
    //   return '';
    // },
    // idSubasta() {
    //   return this.item?.id || null;
    // },
  },
  methods: {
    ...mapActions("auditoriaSubasta", [
      "generarInmediato",
      // "onDateChange",
      // "enviarCorreos",
      // "confirmarProgramacion",
    ]),

    ...mapMutations("auditoriaSubasta", [
      "setModalAuditoria",
      "setModalProgramar",
    ]),

    cambiarSiHayGanador(hayGanador) {
      this.hayGanador = hayGanador;
    },
    async adjudicar(id_subasta) {
      const { status, data } = await this.services.Paac.adjudicarSubastas(
        this.id_proceso,
        id_subasta
      );
      if (status === 200) {
        this.$emit("update_subastas");
        this.pushAppMessage({
          show: true,
          type: "success",
          color: "info",
          message: "Se ha adjudicado la subasta.",
        });
      }
    },
    async enviarCorreos(id_subasta) {
      this.pushAppMessage({
        show: true,
        type: "info",
        color: "info",
        message: "Se procederá a notificar a los involucrados.",
      });
      const { status, data } = await this.services.Paac.enviarCorreosGanadores(
        id_subasta
      );
      if (status === 200) {
        this.$emit("update_subastas");
        this.pushAppMessage({
          show: true,
          type: "success",
          color: "info",
          message: "Se han enviado los correos.",
        });
      }
    },
    generarAhora() {
      // this.$store.commit("auditoriaSubasta/setModalAuditoria", true);
      this.generarInmediato();
      this.setModalAuditoria(false);
    },
    // onDateChange() {
    //   this.horaSeleccionada = null;
    //   this.isTimePickerDisabled = !this.fechaSeleccionada;
    //   this.horaMinima = this.obtenerHoraMinima();
    // },
    // obtenerHoraMinima() {
    //   const now = new Date();
    //   if (this.fechaSeleccionada === this.minFecha) {
    //     const hours = now.getHours();
    //     const minutes = now.getMinutes();
    //     return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    //   } else {
    //     return null;
    //   }
    // },
    // async confirmarProgramacion() {
    //   if (this.fechaSeleccionada && this.horaSeleccionada) {

    //     const { status } = await this.services.Paac.generarAuditoriaSubasta(
    //       this.idSubasta, 
    //       { fecha_seleccionada: this.fechaHoraFormateada }
    //     );
    //   if (status === 200) {
    //     this.pushAppMessage({
    //       show: true,
    //       type: "success",
    //       color: "info",
    //       message: `Reporte programado para: ' ${this.fechaHoraFormateada}`,
    //     });
    //   }
    //     console.log('Reporte programado para:', this.fechaHoraFormateada);

    //     this.cerrarModal();
    //   } else {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Por favor selecciona una fecha y hora válidas.",
    //       type: "info",
    //     });
    //   }
    // },
    // cerrarModal() {
    //   this.modalProgramar = false;
    //   this.fechaSeleccionada = null;
    //   this.horaSeleccionada = null;
    //   this.isTimePickerDisabled = true;
    //   this.horaMinima = null;
    //   this.modalAuditoria = false;
    // },
  },
  watch: {
    item: {
      handler(newItem) {
        if (newItem?.id) {
          this.$store.commit("auditoriaSubasta/setBusqueda", newItem.id);
        }
      },
      deep: true, // Asegura que detecta cambios dentro del objeto
      immediate: true, // Ejecuta el watcher al montar el componente
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
</style>